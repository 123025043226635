export const COUNTRIES = [
    { value: "ABD Virgin Adaları", label: "ABD Virgin Adaları" },
    { value: "Aland Adaları", label: "Aland Adaları" },
    { value: "Almanya", label: "Almanya" },
    { value: "Amerika Birleşik Devletleri", label: "Amerika Birleşik Devletleri" },
    { value: "Andora", label: "Andora" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarktika", label: "Antarktika" },
    { value: "Antigua ve Barbuda", label: "Antigua ve Barbuda" },
    { value: "Arjantin", label: "Arjantin" },
    { value: "Arnavutluk", label: "Arnavutluk" },
    { value: "Aruba", label: "Aruba" },
    { value: "Avustralya", label: "Avustralya" },
    { value: "Avusturya", label: "Avusturya" },
    { value: "Azerbaycan", label: "Azerbaycan" },
    { value: "Bahamalar", label: "Bahamalar" },
    { value: "Bahreyn", label: "Bahreyn" },
    { value: "Bangladeş", label: "Bangladeş" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belize", label: "Belize" },
    { value: "Belçika", label: "Belçika" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Beyaz Rusya", label: "Beyaz Rusya" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Birleşik Arap Emirlikleri", label: "Birleşik Arap Emirlikleri" },
    { value: "Birleşik Krallık", label: "Birleşik Krallık" },
    { value: "Bolivya", label: "Bolivya" },
    { value: "Bosna Hersek", label: "Bosna Hersek" },
    { value: "Botsvana", label: "Botsvana" },
    { value: "Brezilya", label: "Brezilya" },
    { value: "Brunei", label: "Brunei" },
    { value: "Bulgaristan", label: "Bulgaristan" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cebelitarık", label: "Cebelitarık" },
    { value: "Cezayir", label: "Cezayir" },
    { value: "Christmas Adası", label: "Christmas Adası" },
    { value: "Cibuti", label: "Cibuti" },
    { value: "Cocos Adaları", label: "Cocos Adaları" },
    { value: "Cook Adaları", label: "Cook Adaları" },
    { value: "Çad", label: "Çad" },
    { value: "Çek Cumhuriyeti", label: "Çek Cumhuriyeti" },
    { value: "Çin", label: "Çin" },
    { value: "Danimarka", label: "Danimarka" },
    { value: "Dominik", label: "Dominik" },
    { value: "Dominik Cumhuriyeti", label: "Dominik Cumhuriyeti" },
    { value: "Doğu Timor", label: "Doğu Timor" },
    { value: "Ekvator", label: "Ekvator" },
    { value: "Ekvator Ginesi", label: "Ekvator Ginesi" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Endonezya", label: "Endonezya" },
    { value: "Eritre", label: "Eritre" },
    { value: "Ermenistan", label: "Ermenistan" },
    { value: "Estonya", label: "Estonya" },
    { value: "Etiyopya", label: "Etiyopya" },
    { value: "Falkland Adaları (Malvinalar)", label: "Falkland Adaları (Malvinalar)" },
    { value: "Faroe Adaları", label: "Faroe Adaları" },
    { value: "Fas", label: "Fas" },
    { value: "Fiji", label: "Fiji" },
    { value: "Fildişi Sahilleri", label: "Fildişi Sahilleri" },
    { value: "Filipinler", label: "Filipinler" },
    { value: "Filistin Bölgesi", label: "Filistin Bölgesi" },
    { value: "Finlandiya", label: "Finlandiya" },
    { value: "Fransa", label: "Fransa" },
    { value: "Fransız Guyanası", label: "Fransız Guyanası" },
    { value: "Fransız Polinezyası", label: "Fransız Polinezyası" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Gana", label: "Gana" },
    { value: "Gine", label: "Gine" },
    { value: "Gine-Bissau", label: "Gine-Bissau" },
    { value: "Granada", label: "Granada" },
    { value: "Grönland", label: "Grönland" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guyana", label: "Guyana" },
    { value: "Güney Afrika", label: "Güney Afrika" },
    { value: "Güney Georgia ve Güney Sandwich Adaları", label: "Güney Georgia ve Güney Sandwich Adaları" },
    { value: "Güney Kore", label: "Güney Kore" },
    { value: "Güney Kıbrıs Rum Kesimi", label: "Güney Kıbrıs Rum Kesimi" },
    { value: "Gürcistan", label: "Gürcistan" },
    { value: "Haiti", label: "Haiti" },
    { value: "Hindistan", label: "Hindistan" },
    { value: "Hint Okyanusu İngiliz Bölgesi", label: "Hint Okyanusu İngiliz Bölgesi" },
    { value: "Hollanda", label: "Hollanda" },
    { value: "Hollanda Antilleri", label: "Hollanda Antilleri" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong SAR - Çin", label: "Hong Kong SAR - Çin" },
    { value: "Hırvatistan", label: "Hırvatistan" },
    { value: "Irak", label: "Irak" },
    { value: "İngiliz Virgin Adaları", label: "Ingiliz Virgin Adaları" },
    { value: "İran", label: "Iran" },
    { value: "İrlanda", label: "Irlanda" },
    { value: "İspanya", label: "Ispanya" },
    { value: "İsrail", label: "Israil" },
    { value: "İsveç", label: "Isveç" },
    { value: "İsviçre", label: "Isviçre" },
    { value: "İtalya", label: "Italya" },
    { value: "İzlanda", label: "Izlanda" },
    { value: "Jamaika", label: "Jamaika" },
    { value: "Japonya", label: "Japonya" },
    { value: "Jersey", label: "Jersey" },
    { value: "Kamboçya", label: "Kamboçya" },
    { value: "Kamerun", label: "Kamerun" },
    { value: "Kanada", label: "Kanada" },
    { value: "Karadağ", label: "Karadağ" },
    { value: "Katar", label: "Katar" },
    { value: "Kayman Adaları", label: "Kayman Adaları" },
    { value: "Kazakistan", label: "Kazakistan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Kolombiya", label: "Kolombiya" },
    { value: "Komorlar", label: "Komorlar" },
    { value: "Kongo", label: "Kongo" },
    { value: "Kongo Demokratik Cumhuriyeti", label: "Kongo Demokratik Cumhuriyeti" },
    { value: "Kosta Rika", label: "Kosta Rika" },
    { value: "Kuveyt", label: "Kuveyt" },
    { value: "Kuzey Kore", label: "Kuzey Kore" },
    { value: "Kuzey Mariana Adaları", label: "Kuzey Mariana Adaları" },
    { value: "Küba", label: "Küba" },
    { value: "Kırgızistan", label: "Kırgızistan" },
    { value: "Laos", label: "Laos" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Letonya", label: "Letonya" },
    { value: "Liberya", label: "Liberya" },
    { value: "Libya", label: "Libya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Litvanya", label: "Litvanya" },
    { value: "Lübnan", label: "Lübnan" },
    { value: "Lüksemburg", label: "Lüksemburg" },
    { value: "Macaristan", label: "Macaristan" },
    { value: "Madagaskar", label: "Madagaskar" },
    { value: "Makao S.A.R. Çin", label: "Makao S.A.R. Çin" },
    { value: "Makedonya", label: "Makedonya" },
    { value: "Malavi", label: "Malavi" },
    { value: "Maldivler", label: "Maldivler" },
    { value: "Malezya", label: "Malezya" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Man Adası", label: "Man Adası" },
    { value: "Marshall Adaları", label: "Marshall Adaları" },
    { value: "Martinik", label: "Martinik" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Meksika", label: "Meksika" },
    { value: "Mikronezya Federal Eyaletleri", label: "Mikronezya Federal Eyaletleri" },
    { value: "Moldovya Cumhuriyeti", label: "Moldovya Cumhuriyeti" },
    { value: "Monako", label: "Monako" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Moritanya", label: "Moritanya" },
    { value: "Mozambik", label: "Mozambik" },
    { value: "Moğolistan", label: "Moğolistan" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Mısır", label: "Mısır" },
    { value: "Namibya", label: "Namibya" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Nijer", label: "Nijer" },
    { value: "Nijerya", label: "Nijerya" },
    { value: "Nikaragua", label: "Nikaragua" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Adası", label: "Norfolk Adası" },
    { value: "Norveç", label: "Norveç" },
    { value: "Orta Afrika Cumhuriyeti", label: "Orta Afrika Cumhuriyeti" },
    { value: "Özbekistan", label: "Özbekistan" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Panama", label: "Panama" },
    { value: "Papua Yeni Gine", label: "Papua Yeni Gine" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Polonya", label: "Polonya" },
    { value: "Portekiz", label: "Portekiz" },
    { value: "Porto Riko", label: "Porto Riko" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romanya", label: "Romanya" },
    { value: "Ruanda", label: "Ruanda" },
    { value: "Rusya Federasyonu", label: "Rusya Federasyonu" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome ve Principe", label: "Sao Tome ve Principe" },
    { value: "Senegal", label: "Senegal" },
    { value: "Seyşeller", label: "Seyşeller" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapur", label: "Singapur" },
    { value: "Slovakya", label: "Slovakya" },
    { value: "Slovenya", label: "Slovenya" },
    { value: "Solomon Adaları", label: "Solomon Adaları" },
    { value: "Somali", label: "Somali" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Surinam", label: "Surinam" },
    { value: "Suriye", label: "Suriye" },
    { value: "Suudi Arabistan", label: "Suudi Arabistan" },
    { value: "Svalbard ve Jan Mayen", label: "Svalbard ve Jan Mayen" },
    { value: "Svaziland", label: "Svaziland" },
    { value: "Sırbistan", label: "Sırbistan" },
    { value: "Şili", label: "Şili" },
    { value: "Tacikistan", label: "Tacikistan" },
    { value: "Tanzanya", label: "Tanzanya" },
    { value: "Tayland", label: "Tayland" },
    { value: "Tayvan", label: "Tayvan" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad ve Tobago", label: "Trinidad ve Tobago" },
    { value: "Tunus", label: "Tunus" },
    { value: "Turks ve Caicos Adaları", label: "Turks ve Caicos Adaları" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Türkiye", label: "Türkiye" },
    { value: "Türkmenistan", label: "Türkmenistan" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukrayna", label: "Ukrayna" },
    { value: "Umman", label: "Umman" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Ürdün", label: "Ürdün" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Vatikan", label: "Vatikan" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Wallis ve Futuna", label: "Wallis ve Futuna" },
    { value: "Yemen", label: "Yemen" },
    { value: "Yeni Kaledonya", label: "Yeni Kaledonya" },
    { value: "Yeni Zelanda", label: "Yeni Zelanda" },
    { value: "Yunanistan", label: "Yunanistan" },
    { value: "Zambiya", label: "Zambiya" },
    { value: "Zimbabve", label: "Zimbabve" }
]