import React, { useEffect, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { Input, LoadingOverlay, Tooltip } from '@mantine/core';
import { RiEditLine, RiTeamLine } from "react-icons/ri";

import DataTable from 'components/table';

import { Link } from 'react-router-dom';
import axios from 'helpers/axios';
import { useTranslation } from 'react-i18next';
import useDebounce from 'helpers/debounce';
import useUserStore from 'stores/auth';
import showNotification from 'helpers/notification';

export default function OrderList() {

    const columnHelper = createColumnHelper()
    const { t } = useTranslation();
    const { user } = useUserStore();

    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});

    const [clients, setClients] = useState([]);

    useDebounce(() => {
        handleGetAllCampaigns();
    }, [filters], 250);

    const handleGetAllCampaigns = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/clients", { params: filters });
            setClients(results?.clients || [])
        } catch (error) {
            setClients([])
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        columnHelper.accessor('name', {
            header: t('table.client_name'),
            truncate: true,
            full: true,
        }),
        columnHelper.accessor('username', {
            header: t('table.client_code'),
            truncate: true,
        }),
        columnHelper.accessor('is_active', {
            header: t('table.status'),
            truncate: true,
            cell: (row) => row.getValue() ? t('boolean.true') : t('boolean.false')
        }),
        columnHelper.accessor('created_at', {
            header: t('table.created_at'),
            truncate: true,
            date: true
        }),
        columnHelper.accessor('id', {
            header: t('table.actions'),
            cell: row => {
                return (
                    <div className={`w-max grid grid-cols-1 gap-x-2`}>
                        <Tooltip label={t('table.button_edit')}>
                            <Link to={`/clients/${row.getValue()}`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                                <RiEditLine color="white" size={16} />
                            </Link>
                        </Tooltip>
                    </div>
                )
            }
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTeamLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.client.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    {user.type !== "CLIENT" && (
                        <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                            <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                                <div className='flex-1 flex flex-row'>
                                    <h1 className='text-lg font-bold'>{t('filter.title')}</h1>
                                </div>
                            </div>
                            <form className='w-full overflow-x-auto p-6 flex flex-row gap-x-6 gap-y-3' onSubmit={handleGetAllCampaigns}>
                                <div className='flex-1'>
                                    <label className='text-base font-light flex mb-1'>{t('filter.client')}:</label>
                                    <Input size="md" placeholder={t('filter.placeholder')} value={filters.client} onChange={(e) => setFilters(prev => ({ ...prev, client: e.target.value }))} />
                                </div>
                            </form>
                        </div>
                    )}

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.client.title_list')}</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/clients/create"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>{t('pages.client.title_create')}</Link>
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable columns={columns} data={clients} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}