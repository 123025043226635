import React from 'react';

const Footer = () => {
    return (
        <div className="bg-white border-t-1 border-ddd py-6">
            <div className="container mx-auto flex justify-between">
                <span className="text-base">&copy; 2024 - Gürpilsan Plastik</span>
            </div>
        </div>
    );
}

export default Footer;