let validationRegex = [
    { regex: /.{8,}/ }, // min 8 letters,
    { regex: /[0-9]/ }, // numbers from 0 - 9
    { regex: /[a-z]/ }, // letters from a - z (lowercase)
    { regex: /[A-Z]/ }, // letters from A-Z (uppercase),
    { regex: /[^A-Za-z0-9]/ } // special characters
]

const checkPasswordStrengt = (str) => {
    var point = 0;
    validationRegex.forEach((item, i) => {
        if (item.regex.test(str)) point++; else point--;
    })
    return point
}

export default checkPasswordStrengt