import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

const useUserStore = create(persist(
    (set) => ({
        user: {},
        token: "",
        setUser: (entity) => set({ user: entity || {} }),
        setToken: (val) => set({ token: val || "" })
    }), { name: 'user-storage', storage: createJSONStorage(() => localStorage) }
));

export default useUserStore