import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

//stores
import useAuthStore from 'stores/auth';

//components
import Header from 'layouts/header';
import Footer from 'layouts/footer';

//pages
import Login from 'pages/auth/Login';
import Dashboard from 'pages/Dashboard';

import ProductList from 'pages/products/List';
import ProductCreate from 'pages/products/Create';
import ProductEdit from 'pages/products/Edit';

import OrderList from 'pages/orders/List';
import OrderCreate from 'pages/orders/Create';
import OrderItems from 'pages/orders/Edit';

import ClientList from 'pages/clients/List';
import ClientCreate from 'pages/clients/Create';
import ClientEdit from 'pages/clients/Edit';

import TaskList from 'pages/tasks/List';
import TaskEdit from 'pages/tasks/Edit';

import PersonelList from 'pages/personels/List';
import PersonelCreate from 'pages/personels/Create';
import PersonelEdit from 'pages/personels/Edit';

import Profile from 'pages/Profile';

const Navigation = () => {

    const { token } = useAuthStore()

    const PrivateRoute = () => {

        if (!token) {
            return <Navigate to={"/"} replace />;
        }

        return (
            <div>
                <Header />
                <Outlet />
                <Footer />
            </div>
        )
    }

    return (
        <Routes>
            <Route element={<PrivateRoute />}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='profile' element={<Profile />} />
                <Route path='tasks'>
                    <Route path='' element={<TaskList />} />
                    <Route path=':order_item_id' element={<TaskEdit />} />
                </Route>
                <Route path='products'>
                    <Route path='' element={<ProductList />} />
                    <Route path='create' element={<ProductCreate />} />
                    <Route path=':id/edit' element={<ProductEdit />} />
                </Route>
                <Route path='orders'>
                    <Route path='' element={<OrderList />} />
                    <Route path='create' element={<OrderCreate />} />
                    <Route path=':id/edit' element={<OrderItems />} />
                </Route>
                <Route path='clients'>
                    <Route path='' element={<ClientList />} />
                    <Route path='create' element={<ClientCreate />} />
                    <Route path=':id' element={<ClientEdit />} />
                </Route>
                <Route path='personels'>
                    <Route path='' element={<PersonelList />} />
                    <Route path='create' element={<PersonelCreate />} />
                    <Route path=':id' element={<PersonelEdit />} />
                </Route>
            </Route>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<Navigate to={"/"} replace />} />
        </Routes>
    );
}

export default Navigation