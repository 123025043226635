import React, { useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { FileButton, Input, LoadingOverlay, Tooltip } from '@mantine/core';
import { RiExternalLinkLine, RiStackLine } from "react-icons/ri";

import DataTable from 'components/table';

import axios from 'helpers/axios';
import useUserStore from 'stores/auth';
import ProductDetail from 'components/product';
import { useTranslation } from 'react-i18next';
import useDebounce from 'helpers/debounce';
import showNotification from 'helpers/notification';

export default function OrderList() {

    const columnHelper = createColumnHelper()
    const { user } = useUserStore();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [filters, setFilters] = useState({});

    const [products, setProducts] = useState([]);

    useDebounce(() => {
        handleGetAllEntities();
    }, [filters], 250);

    const handleGetAllEntities = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/products", { params: filters });
            setProducts(results?.products || [])
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleSyncProduct = async (e) => {
        if (!e) {
            return
        }
        try {
            setLoading(true)
            const formData = new FormData();
            formData.append('folder', "excel");
            formData.append('file', e);
            await axios.post("/products/sync", formData, { headers: { "Content-type": "multipart/form-data" } });
            handleGetAllEntities();
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        columnHelper.accessor('code', {
            header: t('table.product_code'),
            full: true,
        }),
        columnHelper.accessor('client_name', {
            header: t('table.client_name'),
            truncate: true,
        }),
        columnHelper.accessor('id', {
            header: t('table.actions'),
            cell: row => {
                return (
                    <div className={`w-max grid grid-cols-1 gap-x-2`}>
                        <Tooltip label={t('table.button_show')}>
                            <button onClick={() => setProductId(row.getValue())} className='flex cursor-pointer w-8 h-8 bg-green-500 rounded flex flex-row items-center justify-center'>
                                <RiExternalLinkLine color="white" size={16} />
                            </button>
                        </Tooltip>
                    </div>
                )
            }
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiStackLine color='white' size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.product.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('filter.title')}</h1>
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto p-6 flex flex-row gap-x-6 gap-y-3'>
                            <div className='flex-1'>
                                <label className='text-base font-light flex mb-1'>{t('filter.product')}:</label>
                                <Input size="md" placeholder={t('filter.placeholder')} value={filters.product} onChange={(e) => setFilters(prev => ({ ...prev, product: e.target.value }))} />
                            </div>
                            {user.type !== "CLIENT" && (
                                <div className='flex-1'>
                                    <label className='text-base font-light flex mb-1'>{t('filter.client')}:</label>
                                    <Input size="md" placeholder={t('filter.placeholder')} value={filters.client} onChange={(e) => setFilters(prev => ({ ...prev, client: e.target.value }))} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.product.title_list')}</h1>
                            </div>
                            {user.type === "MANAGER" && (
                                <div className='flex flex-row gap-x-1'>
                                    <FileButton onChange={handleSyncProduct}>
                                        {(props) => <button {...props} className='bg-green-500 px-4 py-2 rounded text-white text-sm'>{t('pages.product.sync')}</button>}
                                    </FileButton>
                                </div>
                            )}
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable columns={columns} data={products} />
                        </div>
                    </div>

                </div>

            </div>

            <ProductDetail productId={productId} setProductId={setProductId} />

        </div>
    );
}