import './assets/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';

import Navigation from './navigation';
import 'moment/locale/tr';
import 'react-spring-bottom-sheet/dist/style.css'
import './i18n';
import i18next from 'i18next';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.documentElement.setAttribute('lang', i18next.language)
i18next.on('languageChanged', (lng) => { document.documentElement.setAttribute('lang', lng); })

root.render(
  <BrowserRouter>
    <MantineProvider>
      <ModalsProvider>
        <Notifications position="bottom-right" zIndex={1000} limit={5} autoClose={3500} />
        <Navigation />
      </ModalsProvider>
    </MantineProvider>
  </BrowserRouter>
);