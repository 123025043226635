import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiAiGenerate, RiTeamLine } from "react-icons/ri";

import axios from 'helpers/axios';
import { randomStr } from 'helpers/unique';
import { useTranslation } from 'react-i18next';
import { COUNTRIES } from 'stores/countries';
import checkPasswordStrengt from 'helpers/password';
import showNotification from 'helpers/notification';

export default function OrderList() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState({ is_active: true });

    const handleCreate = async () => {
        try {
            if (checkPasswordStrengt(entity.password) !== 5) {
                showNotification(t, false, t('alert.password'));
                return;
            }
            setLoading(true)
            const { data: result } = await axios.post("/clients", entity)
            showNotification(t, true, result.message);
            navigate(-1)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTeamLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.client.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.client.title_create')}</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/clients"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>{t('pages.client.title_list')}</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>{t('form.client_code')}:</span>
                                <Input value={entity.username} onChange={e => setEntity(prev => ({ ...prev, username: e.target.value }))} size="md" />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>{t('form.password')}:</span>
                                <Input.Wrapper error={checkPasswordStrengt(entity.password) !== 5 ? t("alert.password") : ""}>
                                    <Input value={entity.password} error={checkPasswordStrengt(entity.password) !== 5} onChange={e => setEntity(prev => ({ ...prev, password: e.target.value }))} rightSection={<button onClick={() => setEntity(prev => ({ ...prev, password: randomStr(15) }))}><RiAiGenerate /></button>} size="md" />
                                </Input.Wrapper>
                            </div>
                            <div className="col-span-12">
                                <label className="text-base font-light mb-1 flex">{t('form.company')}:</label>
                                <Input size="md" value={entity.name} onChange={e => setEntity(prev => ({ ...prev, name: e.target.value }))} />
                            </div>
                            <div className="col-span-6">
                                <label className="text-base font-light mb-1 flex">{t('form.address')}:</label>
                                <Input size="md" value={entity.address} onChange={e => setEntity(prev => ({ ...prev, address: e.target.value }))} />
                            </div>
                            <div className="col-span-6">
                                <label className="text-base font-light mb-1 flex">{t('form.country')}:</label>
                                <Select size="md" searchable value={entity.country} onChange={e => setEntity(prev => ({ ...prev, country: e }))} data={COUNTRIES} />
                            </div>
                            <div className="col-span-6">
                                <label className="text-base font-light mb-1 flex">{t('form.tax')}:</label>
                                <Input size="md" value={entity.tax} onChange={e => setEntity(prev => ({ ...prev, tax: e.target.value }))} />
                            </div>
                            <div className="col-span-6">
                                <label className="text-base font-light mb-1 flex">{t('form.eori')}:</label>
                                <Input size="md" value={entity.eori} onChange={e => setEntity(prev => ({ ...prev, eori: e.target.value }))} />
                            </div>
                            <div className="col-span-4">
                                <label className="text-base font-light mb-1 flex">{t('form.phone')}:</label>
                                <Input size="md" value={entity.phone} onChange={e => setEntity(prev => ({ ...prev, phone: e.target.value }))} />
                            </div>
                            <div className="col-span-4">
                                <label className="text-base font-light mb-1 flex">{t('form.fax')}:</label>
                                <Input size="md" value={entity.fax} onChange={e => setEntity(prev => ({ ...prev, fax: e.target.value }))} />
                            </div>
                            <div className="col-span-4">
                                <label className="text-base font-light mb-1 flex">{t('form.website')}:</label>
                                <Input size="md" value={entity.website} onChange={e => setEntity(prev => ({ ...prev, website: e.target.value }))} />
                            </div>
                            <div className="col-span-12">
                                <label className="text-base font-light mb-1 flex">{t('form.email')}:</label>
                                <Input size="md" value={entity.emails} onChange={e => setEntity(prev => ({ ...prev, emails: e.target.value }))} />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>{t('form.status')}:</span>
                                <Select size="md" placeholder='-' data={[{ value: true, label: t('boolean.true') }, { value: false, label: t('boolean.false') }]} onChange={e => setEntity(prev => ({ ...prev, is_active: e }))} value={entity.is_active} />
                            </div>
                            <button onClick={handleCreate} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>{t('form.button_update')}</button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}