import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiStackLine } from "react-icons/ri";

import axios from 'helpers/axios';
import showNotification from 'helpers/notification';
import { useTranslation } from 'react-i18next';

export default function OrderList() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [entity, setEntity] = useState({});

    useEffect(() => {
        handleFindItem();
    }, [])

    const handleFindItem = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get(`/products/${params.id}`)
            setEntity(results.product || {});
            setClients(results.clients || []);
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = async () => {
        try {
            setLoading(true)
            const { data: result } = await axios.post(`/products/${params.id}`, entity)
            showNotification(t, true, result.message);
            navigate(-1)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiStackLine color='white' size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>ÜRÜN YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>ÜRÜN DÜZENLE</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/products"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TÜM ÜRÜNLER</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>

                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Müşteri:</span>
                                <Select onChange={e => setEntity(prev => ({ ...prev, client_id: e }))} value={entity.client_id} size="md" data={[{ value: "", label: "-" }, ...clients]} />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Ürün Açıklaması:</span>
                                <Input onChange={e => setEntity(prev => ({ ...prev, description: e.target.value }))} value={entity.description} size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Ürün Adı:</span>
                                <Input onChange={e => setEntity(prev => ({ ...prev, name: e.target.value }))} value={entity.name} size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Ürün Kodu:</span>
                                <Input onChange={e => setEntity(prev => ({ ...prev, code: e.target.value }))} value={entity.code} size='md' />
                            </div>

                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Tarih:</span>
                                <Input onChange={e => setEntity(prev => ({ ...prev, date: e.target.value }))} value={entity.date} size='md' />
                            </div>

                            {/* <div className='col-span-12'>
                                diğer bilgiler
                            </div> */}

                            {/* <hr className='col-span-12 my-2' />

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Total Bag Length (mm):</span>
                                <Input size='md' />
                            </div>

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Body Length (mm):</span>
                                <Input size='md' />
                            </div>

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Film Thickness (µm):</span>
                                <Input size='md' />
                            </div>

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Weight Per Bag (g):</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Bag gusset Width (mm):</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Bag open Width (mm):</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Handle Width (mm):</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Handle Length (mm):</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-4'>
                                <span className='font-light mb-1 block'>Bag roll width (mm):</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-4'>
                                <span className='font-light mb-1 block'>Fold Type:</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-4'>
                                <span className='font-light mb-1 block'>Bag Closure Type:</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Bag on roll or flat packed?:</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Sample required?:</span>
                                <Input size='md' />
                            </div>

                            <hr className='col-span-12 my-2' />

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Opacity:</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Blend Details:</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Bag Print Required?</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Bag Colour</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Pantone reference</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>RAL codes</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Bag Print Picture Included Below?</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Bag Print Details</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Ink Colour</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Print Coverag Area/Bag (m^2)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Print Height (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Print Width (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Test Standards</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Drop Test Rating (kg)</span>
                                <Input size='md' />
                            </div>

                            <hr className='col-span-12 my-2' />

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Bags Per Roll</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Roll Per Box</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Net Roll Weight (kg)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Roll Per Polybag</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Polybag Per Box</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Polybag Weight (kg)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Bags Per Box</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Box Net Weight (kg)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Roll Lable</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Polybag Lable</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Finish Roll Size (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Roll Lable Sizes (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Inner Print Details(image)</span>
                                <Input size='md' />
                            </div>

                            <hr className='col-span-12 my-2' />

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Outer Packaging Type</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>No. of Print Colours</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Case Colour</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Box Dimension (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Box Label Type</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Box Label Sizes (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Box Label Position</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Box Image Details</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Box Label Details Long Side/Corner</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Box Label Details Short Side</span>
                                <Input size='md' />
                            </div>

                            <hr className='col-span-12 my-2' />

                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Pallet Type</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Layer Vertical</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Layer Horizontal</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Box Amount/Pallet</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Pallet Height (mm)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Pallet Label</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Pallet Configuration(1)</span>
                                <Input size='md' />
                            </div>
                            <div className='col-span-3'>
                                <span className='font-light mb-1 block'>Pallet Configuration(2)</span>
                                <Input size='md' />
                            </div> */}

                            <button onClick={handleUpdate} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>
                                Kaydet!
                            </button>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}