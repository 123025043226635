import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { useFullscreen } from '@mantine/hooks';

import { RiLogoutBoxLine, RiFileMarkLine, RiTeamLine, RiFullscreenLine, RiFullscreenExitLine, RiBookLine, RiMenuLine, RiTaskLine, RiStackLine, RiListOrdered, RiUserSmileLine, RiUserStarLine, RiUserSettingsLine, RiHome2Line } from "react-icons/ri";

import { Tooltip } from '@mantine/core';
import useUserStore from 'stores/auth';
import axios from 'helpers/axios';
import { useTranslation } from 'react-i18next';

import Logo from "assets/logo.png";

const Header = () => {

    const location = useLocation();
    const { toggle, fullscreen } = useFullscreen();
    const { t, i18n } = useTranslation();

    const { user, setToken } = useUserStore();

    const _handleLogout = () => {
        setToken(null)
        delete axios.defaults.headers.authorization;
        window.location.href = "/"
    }

    const active_menu = (current_path) => {
        return `border-b-4 border-transparent h-16 flex flex-row justify-center align-center items-center hover:border-red-600 ${location.pathname.includes(current_path) ? "!border-red-600" : ""}`;
    }

    return (
        <div className="bg-white shadow-sm z-50">
            <div className="container mx-auto">

                <div className="flex flex-row justify-between py-6">
                    <div className="flex flex-row items-center justify-center">
                        <Link to="/dashboard">
                            <img src={Logo} alt="" style={{ height: 60 }} />
                        </Link>
                        <span className='border-l border-slate-100 ml-4 pl-4 font-light text-slate-600 text-lg'>{t("app_description")}</span>
                    </div>
                    <div className="flex flex-col justify-center">
                        <div className="flex flex-row justify-center relative items-center gap-x-3">

                            <div className='flex flex-row-reverse'>
                                <Tooltip label={t("header.fullscreen")}>
                                    <div onClick={toggle} className="el-tooltip w-10 h-10 bg-slate-200 rounded flex flex-col justify-center items-center cursor-pointer mx-1 item">
                                        {fullscreen ? <RiFullscreenExitLine color="black" size={18} /> : <RiFullscreenLine color="black" size={18} />}
                                    </div>
                                </Tooltip>
                                <Tooltip label={t("header.logout")}>
                                    <div onClick={_handleLogout} className="el-tooltip w-10 h-10 bg-red-600 rounded flex flex-col justify-center items-center cursor-pointer item mx-1">
                                        <RiLogoutBoxLine color="white" size={18} />
                                    </div>
                                </Tooltip>
                                <div className="flex flex-col justify-center text-right mx-2 text-lg">
                                    <div className="font-bold text-base leading-5 uppercase">{user.name}</div>
                                    <div className="font-light text-base leading-5">{user.type}</div>
                                </div>
                            </div>
                            <span className='text-sm text-slate-200'>|</span>
                            <div className='flex flex-row items-center gap-x-3'>
                                <Tooltip label={t("header.profile")}>
                                    <Link to="/profile" className="el-tooltip w-10 h-10 bg-slate-200 text-slate-900 rounded flex flex-col justify-center items-center cursor-pointer item">
                                        <RiUserSettingsLine size={18} />
                                    </Link>
                                </Tooltip>
                                <span className='text-sm text-slate-200'>|</span>
                                <div className='flex flex-row items-center justify-end gap-x-1'>
                                    <button className={`${i18n.language === "tr" ? "opacity-100" : "opacity-50"} w-10 h-10 bg-slate-200 text-slate-900 rounded flex flex-col justify-center items-center cursor-pointer`} onClick={() => i18n.changeLanguage("tr")}><img src="/flag/tr.png" alt="" /></button>
                                    <button className={`${i18n.language === "en" ? "opacity-100" : "opacity-50"} w-10 h-10 bg-slate-200 text-slate-900 rounded flex flex-col justify-center items-center cursor-pointer`} onClick={() => i18n.changeLanguage("en")}><img src="/flag/en.png" alt="" /></button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="menu flex flex-row border-t border-slate-100 divide-x divide-slate-100">
                    <Link to="/dashboard" className={active_menu('/dashboard') + " px-4"}>
                        <RiHome2Line color="gray" size={18} />
                    </Link>
                    {user.type === "MANAGER" && (
                        <div className="flex-auto">
                            <Link to="/clients" className={active_menu('/clients')}>
                                <RiBookLine color="gray" size={18} />
                                <span className="text-slate-800 pl-2 font-light text-base">{t("pages.client.top")}</span>
                            </Link>
                        </div>
                    )}
                    <div className="flex-auto">
                        <Link to="/products" className={active_menu('/products')}>
                            <RiMenuLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">{t("pages.product.top")}</span>
                        </Link>
                    </div>
                    {user.type !== "QUALITY" && (
                        <div className="flex-auto relative">
                            <Link to="/orders" className={active_menu('/orders')}>
                                <RiTeamLine color="gray" size={18} />
                                <span className="text-slate-800 pl-2 font-light text-base">{t("pages.order.top")}</span>
                            </Link>
                        </div>
                    )}
                    <div className="flex-auto">
                        <Link to="/tasks" className={active_menu('/tasks')}>
                            <RiBookLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">{t("pages.task.top")}</span>
                        </Link>
                    </div>
                    {user.type === "MANAGER" && (
                        <div className="flex-auto">
                            <Link to="/personels" className={active_menu('/personels')}>
                                <RiFileMarkLine color="gray" size={18} />
                                <span className="text-slate-800 pl-2 font-light text-base">{t("pages.user.top")}</span>
                            </Link>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default Header;
