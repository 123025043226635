import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button, FileInput, Group, Input, LoadingOverlay, Modal, Select, TextInput, Tooltip } from '@mantine/core';
import { RiCheckLine, RiCloseLine, RiFile2Line, RiTaskLine, RiUploadLine, RiVideoLine } from "react-icons/ri";

import axios, { BASE_URL } from 'helpers/axios';
import useUserStore from 'stores/auth';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import ProductDetail from 'components/product';
import { useTranslation } from 'react-i18next';
import showNotification from 'helpers/notification';
import moment from 'moment-timezone';

export default function OrderList() {

    const navigate = useNavigate();
    const params = useParams();
    const { t, i18n } = useTranslation();
    const [openedRejectModal, { toggle: toggleRejectModal, close: closeRejectModal }] = useDisclosure(false);

    const [loading, setLoading] = useState(false);

    const [isOpenQualityModal, setOpenQualityModal] = useState(false)
    const [productId, setProductId] = useState(false)

    const [order, setOrder] = useState({});
    const [actions, setActions] = useState([]);
    const [quality, setQuality] = useState([]);

    const [message, setMessage] = useState("");

    const { user } = useUserStore();

    useEffect(() => {
        handleGetEntity();
    }, [])

    const handleGetEntity = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get(`/tasks/edit/${params.order_item_id}`);
            setOrder({ ...results?.entity, quality: null } || {})
            setQuality(JSON.parse(results?.entity?.quality))
            setActions(results?.entities || [])
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateTask = async (message) => {
        try {
            setLoading(true)
            const { data: result } = await axios.post(`/tasks/update/${params.order_item_id}`, { message: message });
            showNotification(t, true, result.message);
            navigate(-1)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleUpload = async (e, key, folder) => {
        setLoading(true)
        if (!e) {
            setLoading(false)
            setQuality(prev => prev?.map(p => p.key === key ? ({ ...p, value: null }) : p))
            return
        }
        const formData = new FormData();
        formData.append('folder', folder);
        formData.append('file', e);
        const { data: upload_response } = await axios.post("/uploads", formData, { headers: { "Content-type": "multipart/form-data" } });
        setQuality(prev => prev?.map(p => p.key === key ? ({ ...p, value: upload_response }) : p))
        setLoading(false)
    }

    const handleUpdateOrderQuality = async () => {
        try {
            setLoading(true)
            const { data: result } = await axios.post(`/tasks/update/${params.order_item_id}/quality`, quality);
            showNotification(t, true, result.message);
            setOpenQualityModal(false)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const RenderActionType = ({ code, message }) => {
        var sectures = { "mg_init": "Sipariş Oluşturuldu!", "kk_done": "Kalite Onayladı!", "mg_done": "Yönetici Onayladı!", "cl_done": "Müşteri Onayladı!" }
        if (message != null) return code === "mg_done" ? `Yönetici Reddetti! - (${message})` : `Müşteri Reddetti! - (${message})`;
        return sectures[code];
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTaskLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.task.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='col-span-12 bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.task.information')}</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <button className='bg-blue-500 mx-1 px-4 py-2 rounded text-sm text-white' onClick={() => setProductId(order?.product_id)}>{t('pages.product.title_show')}</button>
                                <Link to={"/tasks"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>{t('pages.task.title_list')}</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>{t('table.order_code')}:</span>
                                <Input value={order?.code} disabled size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>{t('table.order_date')}:</span>
                                <Input value={moment.utc(order?.created_at).tz(i18n.language === "tr" ? "Europe/Istanbul" : "GMT").format('DD-MM-YYYY HH:mm:ss')} disabled size="md" />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>{t('form.company')}:</span>
                                <Input value={order?.client_name} disabled size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light block pr-px'>{t('table.product_code')}:</span>
                                <Input value={order?.product_code} disabled size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light block pr-px'>{t('form.product')}:</span>
                                <Input value={order?.product_name} disabled size="md" />
                            </div>
                            <div className='col-span-4'>
                                <span className='font-light mb-1 block'>{t('table.type')}:</span>
                                <Input value={order?.type} disabled size="md" />
                            </div>
                            <div className='col-span-4'>
                                <span className='font-light mb-1 block'>{t('table.weight')}:</span>
                                <Input value={order?.weight} disabled size="md" />
                            </div>
                            <div className='col-span-4'>
                                <span className='font-light mb-1 block'>{t('table.qty')}:</span>
                                <Input value={order?.qty} disabled size="md" />
                            </div>
                        </div>
                    </div>

                    <div className='col-span-12 bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>

                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold uppercase'>{t('pages.task.control')}</h1>
                            </div>
                            {user.type !== "CLIENT" && (
                                <div onClick={() => setOpenQualityModal(true)}>
                                    <button className='bg-green-500 px-4 py-2 rounded flex flex-row items-center justify-center text-white text-sm'>{t('table.button_edit')}</button>
                                </div>
                            )}
                        </div>
                        <div className='flex-1 flex flex-col p-6 gap-y-4'>
                            <div className='grid grid-cols-12 gap-2'>
                                {quality.filter(q => q.type !== "file")?.map(q => {
                                    return (
                                        <div className={`${q.key === "note" ? "col-span-6" : "col-span-2"} flex flex-row items-center justify-start bg-slate-50 rounded py-2 px-4`}>
                                            <label className='text-sm font-light block mr-1'>{t(`quality.${q.key}`)}:</label>
                                            {q.type === "text" && <span className='text-xs font-light'>{q.value || "-"}</span>}
                                            {q.type === "bool" && <span className='text-xs font-light'>{q.value ? <RiCheckLine color="green" /> : <RiCloseLine color="red" />}</span>}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='grid grid-cols-12 gap-4'>
                                {quality.filter(q => q.type === "file")?.map(q => {
                                    if (!q.value) return false;
                                    return (
                                        <div className='col-span-2 relative'>
                                            <p className='text-xs font-light mb-1'>{t(`quality.${q.key}`)}:</p>
                                            <div className='aspect-square shadow-sm border rounded'>
                                                {q.content === "video" ? (
                                                    <button
                                                        onClick={() => modals.open({
                                                            title: t('quality.popup_title'), centered: true, children: (
                                                                <video autoPlay controls src={`${BASE_URL}/uploads` + q.value}></video>
                                                            )
                                                        })}
                                                        className='w-full h-full flex flex-col text-sm items-center justify-center gap-y-3'>
                                                        <RiVideoLine size={30} />
                                                        <span>{t('quality.video')}</span>
                                                    </button>
                                                ) : <img onClick={() => modals.open({ title: 'Görsel', centered: true, children: (<a target="_blank" rel="noreferrer" href={`${BASE_URL}/uploads` + q.value}><img src={`${BASE_URL}/uploads` + q.value} alt="" /></a>) })} className='cursor-pointer w-full h-full object-cover rounded overflow-hidden' src={`${BASE_URL}/uploads${q.value}`} alt="" />}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    {user.type !== "CLIENT" && (
                        <div className='col-span-12 bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                            <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                                <div className='flex-1 flex flex-row'>
                                    <h1 className='text-lg font-bold uppercase'>{t('pages.task.actions')}</h1>
                                </div>
                            </div>
                            <div className='flex-1 flex flex-col divide-y divide-slate-100'>
                                {actions?.map(a => (
                                    <div className='flex flex-row items-center justify-between py-3 px-6'>
                                        <p className='text-base font-light'>{a.name} - <RenderActionType code={a.code} message={a.message} /></p>
                                        <small className='text-sm font-light'>{moment.utc(a?.created_at).tz(i18n.language === "tr" ? "Europe/Istanbul" : "GMT").format('DD-MM-YYYY HH:mm:ss') || "-"}</small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {((user.type === "MANAGER" && (order.status === "kk_done" || (order.status === "cl_done" && order.message?.length > 0))) || (user.type === "QUALITY" && ((order.status === "mg_init") || (order.status === "mg_done" && order.message?.length > 0))) || (user.type === "CLIENT" && (order.status === "mg_done"))) && (
                        <div className='col-span-12 bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                            <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                                <div className='flex-1 flex flex-row'>
                                    <h1 className='text-lg font-bold'>{t('actions.title')}</h1>
                                </div>
                            </div>
                            <div className='flex-1 p-6 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <div className='col-span-12 flex flex-row gap-x-4'>
                                    {user.type !== "QUALITY" && <button onClick={toggleRejectModal} className='col-span-6 bg-red-500 w-full py-4 rounded text-white font-light'>{t('actions.button_reject')}</button>}
                                    <button onClick={() => handleUpdateTask(message)} className={`${user.type !== "QUALITY" ? "col-span-6" : "col-span-12"} bg-green-500 w-full py-4 rounded text-white font-light`}>{t('actions.button_done')}</button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>

            <BottomSheet onDismiss={() => setOpenQualityModal(null)} onSpringStart={(_) => null} open={isOpenQualityModal} scrollLocking={true} maxHeight={window.screen.height / 1.5} header={false}>
                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center justify-between py-5 text-lg font-light text-white'>
                        <h1>{t('quality.title')}</h1>
                        <button onClick={() => setOpenQualityModal(false)}><RiCloseLine /></button>
                    </div>
                </div>
                <div className='container mx-auto grid grid-cols-12 gap-6 py-6'>
                    {quality.map(q => {
                        return (
                            <div className='col-span-4'>
                                <div className='flex flex-row items-center justify-between'>
                                    <label className='text-sm flex mb-1'>{t(`quality.${q.key}`)}:</label>
                                    {(q.type === "file" && q.value) && <Tooltip label="Önizleme"><a target='_blank' className='text-sm flex mb-1' href={`${BASE_URL}/uploads${q?.value}`} rel="noreferrer"><RiFile2Line /></a></Tooltip>}
                                </div>
                                {q.type === "text" && (
                                    <Input size='md' value={q.value} onChange={e => setQuality(prev => prev?.map(p => p.key === q.key ? ({ ...p, value: e.target.value }) : p))} />
                                )}
                                {q.type === "file" && (
                                    <FileInput rightSection={q.value ? <button onClick={(e) => handleUpload(null, q.key, "quality")}><RiCloseLine /></button> : ""} icon={<RiUploadLine color='gray' size={16} />} value={null} placeholder={q.value?.slice(9, 35)?.length > 0 ? q.value?.slice(9, 35) + "..." : "-"} onChange={e => handleUpload(e, q.key, "quality")} size='md' />
                                )}
                                {q.type === "bool" && (
                                    <Select size='md' value={q.value} onChange={e => setQuality(prev => prev?.map(p => p.key === q.key ? ({ ...p, value: e }) : p))} data={[{ value: true, label: t('boolean.aproved') }, { value: false, label: t('boolean.reject') }]} />
                                )}
                            </div>
                        )
                    })}
                    <button onClick={handleUpdateOrderQuality} className='col-span-12 bg-green-500 text-white py-4 rounded'>{t('form.button_update')}</button>
                </div>
            </BottomSheet>

            <ProductDetail productId={productId} setProductId={setProductId} />
            <Modal opened={openedRejectModal} centered onClose={closeRejectModal} title={t('actions.reject_title')}>
                <Group align="flex-center">
                    <TextInput placeholder="" value={message} onChange={e => setMessage(e.target.value)} style={{ flex: 1 }} />
                    <Button onClick={() => handleUpdateTask(message)}>{t('actions.reject_save')}</Button>
                </Group>
            </Modal>
        </div>
    );
}