import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import TrJson from "./locales/tr/translation.json";
import EnJson from "./locales/en/translation.json";

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        tr: { translation: TrJson },
        en: { translation: EnJson }
    },
    fallbackLng: 'tr',
    debug: false,
    interpolation: { escapeValue: false }
});

export default i18n;