import axios, { BASE_URL } from "helpers/axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { BottomSheet } from "react-spring-bottom-sheet";

const ProductDetail = ({ productId, setProductId }) => {

    const { t } = useTranslation();

    const [product, setProduct] = useState({});
    const [options, setOptions] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!productId) return
        handleGetProduct()
    }, [productId])

    const handleGetProduct = async () => {
        try {
            const { data: result } = await axios.get(`/products/${productId}/show`);
            setProduct({ ...result.product, options: null } || {})
            setOptions(JSON.parse(result.product.options))
        } catch (error) {
            alert(error)
        } finally {
            setLoading(false)
        }
    }

    if (loading) return

    return (
        <BottomSheet onDismiss={() => setProductId(null)} onSpringStart={(_) => null} open={!!productId} scrollLocking={true} maxHeight={window.screen.height / 1.5} header={false}>
            <div>
                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center justify-between py-5 text-lg font-light text-white'>
                        <h1>{t('product.details')}</h1>
                        <button onClick={() => setProductId(false)}><RiCloseLine /></button>
                    </div>
                </div>
                <div className='container mx-auto flex flex-col py-6 gap-y-4'>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t('product.information')}</h1>
                        <div className="w-full grid grid-cols-12 gap-x-4 gap-y-2 text-sm">
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.description')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{product?.description}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.date')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{product?.date || "-"}</span>
                            </div>
                            <div className="col-span-12 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.product_name')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{product?.name}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.client_name')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{product?.client_name || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.code')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{product?.code || "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t('product.details')}</h1>
                        <div className="w-full grid grid-cols-12 gap-x-4 gap-y-2 text-sm">
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.total_bag_length')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.total_bag_length_mm} / {options?.total_bag_length_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_gusset_width')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.bag_gusset_width_mm} / {options?.bag_gusset_width_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.body_length')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.body_length_mm} / {options?.body_length_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_open_width')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.bag_open_width_mm} / {options?.bag_open_width_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.film_thickness')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.film_tickness_mm}/ {options?.film_tickness_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.handle_width')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.handle_width_mm} / {options?.handle_width_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.weight_per_bag')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.weight_per_bag_g || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.handle_length')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.handle_lenght_mm} / {options?.handle_lenght_per}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_closure_type')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.bag_closure_type || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_on_roll_flat_packed')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.bag_on_roll_or_flat_packed || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.sample_required')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.sample_required || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_roll_width')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.bag_roll_width_mm} / {options?.bag_roll_width_per}</span>
                            </div>
                            <div className="col-span-6"></div>
                            <div className="col-span-12 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.fold_type')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150 ml-3">{options?.fold_type || "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t('product.performance')}</h1>
                        <div className="w-full grid grid-cols-12 gap-x-4 gap-y-2 text-sm">

                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.opacity')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.opacity || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_color')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.bag_colour || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.blend_details')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.blend_details || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.pantone_reference')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.pantone_reference || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_print_required')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.bag_print_required || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.ral_codes')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.ral_codes || "-"}</span>
                            </div>
                            <div className="col-span-12 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.included_below')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.include_below || "-"}</span>
                            </div>
                            <div className="col-span-12 flex flex-col gap-x-2">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bag_print_details')}:</strong>
                                <div className="w-full h-auto border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.bag_print_details ? <img src={`${BASE_URL}/uploads${options.bag_print_details}`} className="object-contain" alt="" /> : "-"}
                                </div>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.ink_color')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.ink_colour || "-"}</span>
                            </div>
                            <div className="col-span-6 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.print_coverage_area')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.print_coverage_area || "-"}</span>
                            </div>

                            <div className="col-span-12 flex flex-row gap-x-2">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.print_height')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.print_height || "-"}</span>
                            </div>
                            <div className="col-span-12 flex flex-row gap-x-2">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.print_width')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.print_width || "-"}</span>
                            </div>

                            <div className="col-span-12 flex flex-row gap-x-2">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.test_standarts')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.test_standarts || "-"}</span>
                            </div>
                            <div className="col-span-12 flex flex-row gap-x-2">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.drop_test_rating')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.drop_test_rating || "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t('product.inner_packaging')}</h1>
                        <div className="w-full grid grid-cols-12 grid-rows-12 gap-x-4 gap-y-2 text-sm">
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bags_per_roll')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.bags_per_roll || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-12 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.inner_print_details')}:</strong>
                                <div className="w-full h-full border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.inner_print_details ? <img src={`${BASE_URL}/uploads${options.inner_print_details}`} className="object-contain" alt="" /> : "-"}
                                </div>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.roll_per_box')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.roll_per_box || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.net_roll_weight')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.net_roll_weight || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.roll_per_polybag')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.roll_per_polybag || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.polybag_per_box')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.polybag_per_box || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_weight')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.polybag_weight || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.bags_per_box')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.bags_per_box || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_net_weight')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.box_net_weight || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.roll_label')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.roll_lable || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.polybag_label')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.polybag_lable || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.finish_roll_size')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.finish_roll_size || "-"}</span>
                            </div>
                            <div className="col-span-6 row-span-1 flex flex-row items-center">
                                <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.roll_label_size')}:</strong>
                                <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.roll_lable_sizes || "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t('product.outer_packaging')}</h1>
                        <div className="w-full grid grid-cols-12 gap-4 text-sm">

                            <div className="col-span-6 flex flex-col gap-x-4 gap-y-2">
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.packaging_type')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.outer_packaging_type || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.print_colors')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.no_print_colours || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.case_color')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.case_colour || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_dimessions')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.box_dimession_w} / {options?.box_dimession_l} / {options?.box_dimession_h}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_label_type')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.box_label_type || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_label_sizes')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.box_label_sizes || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_label_position')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.box_label_position || "-"}</span>
                                </div>
                            </div>

                            <div className="col-span-6 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.box_image_details')}:</strong>
                                <div className="w-full h-full border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.box_image_details ? <img src={`${BASE_URL}/uploads${options.box_image_details}`} className="object-contain" alt="" /> : "-"}
                                </div>
                            </div>

                            <div className="col-span-6 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.box_label_long_side')}:</strong>
                                <div className="w-full h-auto border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.box_label_details_log_side ? <img src={`${BASE_URL}/uploads${options.box_label_details_log_side}`} className="object-contain w-[300px] h-[200px]" alt="" /> : "-"}
                                </div>
                            </div>
                            <div className="col-span-6 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.box_label_short_side')}:</strong>
                                <div className="w-full h-auto border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.box_label_details_short_side ? <img src={`${BASE_URL}/uploads${options.box_label_details_short_side}`} className="object-contain w-[300px] h-[200px]" alt="" /> : "-"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t('product.configuration')}</h1>
                        <div className="w-full grid grid-cols-12 gap-3 text-sm">

                            <div className="col-span-6 flex flex-col gap-y-2">
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.pallet_type')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.pallet_type || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.layer_vertical')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.layer_vertical || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.layer_horizontal')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.layer_horizontal || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.box_amount')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.box_amount || "-"}</span>
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <strong className="font-semibold text-slate-700 border border-slate-150 p-2">{t('product.pallet_height')}:</strong>
                                    <span className="flex-1 ml-3 p-2 border border-slate-150">{options?.pallet_height || "-"}</span>
                                </div>

                            </div>

                            <div className="col-span-6 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.pallet_label')}:</strong>
                                <div className="w-full h-auto border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.pallet_label ? <img src={`${BASE_URL}/uploads${options.pallet_label}`} className="object-contain w-[300px] h-[200px]" alt="" /> : "-"}
                                </div>
                            </div>

                            <strong className="col-span-12 w-full h-auto border border-slate-150 p-4 shadow-sm flex flex-row items-center justify-center">{t('product.pallet_config')}:</strong>

                            <div className="col-span-6 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.1st_layer')}:</strong>
                                <div className="w-full h-auto border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.pallet_1st ? <img src={`${BASE_URL}/uploads${options.pallet_1st}`} className="object-contain w-[300px] h-[200px]" alt="" /> : "-"}
                                </div>
                            </div>

                            <div className="col-span-6 flex flex-col">
                                <strong className="mb-1 font-semibold text-slate-700">{t('product.2nd_layer')}:</strong>
                                <div className="w-full h-auto border border-slate-150 p-6 shadow-sm flex flex-row items-center justify-center">
                                    {options.pallet_2nd ? <img src={`${BASE_URL}/uploads${options.pallet_2nd}`} className="object-contain w-[300px] h-[200px]" alt="" /> : "-"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <h1 className="bg-slate-100 px-4 py-4 rounded text-base text-center font-light uppercase">{t("product.history")}</h1>
                        <div className="w-full grid grid-cols-12 gap-x-4 text-sm">
                            {(options.histories || []).length > 0 ? (
                                <table className="col-span-12">
                                    <thead>
                                        <tr className="border">
                                            <td className="p-4 font-bold">{t('history.date')}</td>
                                            <td className="p-4 font-bold border-l">{t('history.note')}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {options.histories?.map(a => {
                                            if (!a.date || !a.note) return false;
                                            return (
                                                <tr className="border">
                                                    <td className="p-4 ">{a?.date}</td>
                                                    <td className="p-4 border-l">{a?.note}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <h1 className="col-span-12 text-center">{t("no")}</h1>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </BottomSheet>
    )
}

export default ProductDetail;