import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { LoadingOverlay, Tooltip } from '@mantine/core';

import axios from 'helpers/axios';
import { RiArrowRightLine, RiShutDownLine } from 'react-icons/ri';
import DataTable from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import useUserStore from 'stores/auth';
import { useTranslation } from 'react-i18next';
import { RenderIcon } from 'helpers/task';
import showNotification from 'helpers/notification';

export default function Dashboard() {

    const columnHelper = createColumnHelper()
    const { user } = useUserStore();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [numbers, setNumbers] = useState({});
    const [tasks, setTasks] = useState([]);

    const columns = [
        columnHelper.accessor('order_code', {
            header: t('table.order_code'),
        }),
        columnHelper.accessor('product_code', {
            header: t('table.product_code'),
            full: true,
        }),
        columnHelper.accessor('client_name', {
            header: t('table.client_name'),
        }),
        columnHelper.accessor('type', {
            header: t('table.type'),
            truncate: true
        }),
        columnHelper.accessor('weight', {
            header: t('table.weight'),
            truncate: true
        }),
        columnHelper.accessor('qty', {
            header: t('table.qty'),
            truncate: true
        }),
        columnHelper.accessor('status', {
            header: t('table.status'),
            truncate: true,
            cell: (row) => <RenderIcon code={row.getValue()} message={row.row.original.message} />
        }),
        columnHelper.accessor('id', {
            header: t('table.actions'),
            cell: row => {
                return (
                    <div className={`w-max grid ${user.type === "MANAGER" ? "grid-cols-2" : "grid-cols-1"} gap-x-2`}>
                        {user.type === "MANAGER" && (
                            <Tooltip label={t('table.button_toggle_hide')}>
                                <button onClick={() => handleUpdateTask(row.getValue())} className={`flex cursor-pointer w-8 h-8 ${row.row.original.is_hidden ? "bg-green-500" : "bg-red-500"} rounded flex flex-row items-center justify-center`}>
                                    <RiShutDownLine color="white" size={15} />
                                </button>
                            </Tooltip>
                        )}
                        <Tooltip label={t('table.button_review')}>
                            <Link to={`/tasks/${row.getValue()}`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                                <RiArrowRightLine color="white" size={16} />
                            </Link>
                        </Tooltip>
                    </div>
                )
            }
        }),
    ]

    useEffect(() => {
        handleGetDashboardData();
    }, [])

    const handleGetDashboardData = async () => {
        try {
            const { data: dashboard_result } = await axios.get("/");
            setNumbers(dashboard_result?.numbers || {})
            setTasks(dashboard_result?.tasks || [])
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateTask = async (id) => {
        try {
            setLoading(true)
            await axios.delete(`/tasks/${id}`);
            handleGetDashboardData();
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-6'>
                <div>
                    <div className='flex flex-row items-center justify-between mb-4'>
                        <h2 className="text-xl uppercase font-semibold text-slate-700">
                            {t("home.stats_title")}
                        </h2>
                    </div>
                    <div className="grid grid-cols-12 grid-rows-1 gap-x-6 gap-y-6">

                        <Link to={{ pathname: "/tasks" }} className="col-span-12 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                            <span className="text-slate-700 text-xl font-semibold">{numbers?.task ?? 0}</span>
                            <strong className="font-light text-slate-500 text-lg">
                                {t("home.task")}
                            </strong>
                        </Link>

                        <div className='col-span-12 flex flex-row gap-x-6'>

                            {user.type !== "QUALITY" && (
                                <Link to={{ pathname: "/orders" }} className="flex-1 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                                    <span className="text-slate-700 text-xl font-semibold">{numbers?.order ?? 0}</span>
                                    <strong className="font-light text-slate-500 text-lg">
                                        {t("home.order")}
                                    </strong>
                                </Link>
                            )}

                            {user.type === "MANAGER" && (
                                <Link to={{ pathname: "/clients" }} className="flex-1 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                                    <span className="text-slate-700 text-xl font-semibold">{numbers?.client ?? 0}</span>
                                    <strong className="font-light text-slate-500 text-lg">
                                        {t("home.client")}
                                    </strong>
                                </Link>
                            )}

                            <Link to={{ pathname: "/products" }} className="flex-1 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                                <span className="text-slate-700 text-xl font-semibold">{numbers?.product ?? 0}</span>
                                <strong className="font-light text-slate-500 text-lg">
                                    {t("home.product")}
                                </strong>
                            </Link>

                        </div>

                    </div>
                </div>
                <div>
                    <div className='flex flex-row items-center justify-between mb-4'>
                        <h2 className="text-xl uppercase font-semibold text-slate-700">
                            {t("home.task_title")}
                        </h2>
                    </div>

                    <div className='bg-white shadow-sm border border-slate-100'>
                        <DataTable paginate columns={columns} data={tasks} message={t('no')} />
                    </div>
                </div>

            </div>

        </div>
    );
}
