import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiAddLine, RiAiGenerate, RiDeleteBin4Line, RiListOrdered } from "react-icons/ri";

import axios from 'helpers/axios';
import { randomStr } from 'helpers/unique';
import { useTranslation } from 'react-i18next';
import showNotification from 'helpers/notification';

export default function OrderList() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [products, setProducts] = useState([]);

    const [items, setItems] = useState([]);
    const [entity, setEntity] = useState({});

    const productsFiltered = useMemo(() => products.filter(p => p.client_id === entity.client_id), [entity.client_id])

    useEffect(() => {
        handleGetAllCategories();
    }, [])

    const handleGetAllCategories = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get(`/orders/create`);
            setProducts(results?.products || [])
            setClients(results?.clients || [])
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleCreate = async () => {
        try {
            setLoading(true)
            const { data: result } = await axios.post("/orders", { ...entity, items: items })
            showNotification(t, true, result.message);
            navigate(-1)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiListOrdered color='white' size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.order.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.order.title_create')}</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/orders"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>{t('pages.order.title_list')}</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>

                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>{t('table.client_name')}</span>
                                <Select size='md' value={entity.client_id} onChange={e => {
                                    setEntity(prev => ({ ...prev, client_id: e }))
                                    setItems([])
                                }} data={clients} />
                            </div>

                            <div className='col-span-12'>
                                <span className='font-light mb-1 block text-sm'>{t('table.order_code')}: <small>&mdash; {t('form.automatic')}</small></span>
                                <Input size='md' rightSection={<button onClick={() => setEntity(prev => ({ ...prev, code: randomStr(12) }))}><RiAiGenerate /></button>} value={entity.code} onChange={e => setEntity(prev => ({ ...prev, code: e.target.value }))} />
                            </div>

                        </div>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>

                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.order.title_create')}</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <button onClick={() => setItems(prev => [...prev, {}])} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'><RiAddLine /></button>
                            </div>
                        </div>

                        <div className='w-full flex flex-col'>

                            {items?.length > 0 ? items?.map((item, i) => (
                                <div className='flex flex-row px-6 py-2 gap-x-4'>

                                    <div className='flex-auto'>
                                        <span className='font-light mb-1 block text-sm'>#</span>
                                        <Input size="sm" disabled value={i + 1} />
                                    </div>

                                    <div className='flex-[100%]'>
                                        <span className='font-light mb-1 block text-sm'>{t('table.product')}:</span>
                                        <Select size="sm" placeholder='-' data={productsFiltered} value={items[i].product_id} onChange={(e) => setItems(prev => prev.map((item, index) => index == i ? { ...item, product_id: e } : item))} />
                                    </div>

                                    <div className='flex-auto'>
                                        <span className='font-light mb-1 block text-sm'>{t('form.type')}:</span>
                                        <Select size="sm" placeholder='-' data={[{ value: "Box", label: t('type.Box') }, { value: "Polybag", label: t('type.Polybag') }]} value={items[i].type} onChange={(e) => setItems(prev => prev.map((item, index) => index == i ? { ...item, type: e } : item))} />
                                    </div>

                                    <div className='flex-auto'>
                                        <span className='font-light mb-1 block text-sm'>{t('form.weight')}:</span>
                                        <Input size="sm" type="number" value={items[i].weight} onChange={(e) => setItems(prev => prev.map((item, index) => index == i ? { ...item, weight: e.target.value } : item))} />
                                    </div>

                                    <div className='flex-auto'>
                                        <span className='font-light mb-1 block text-sm'>{t('form.qty')}:</span>
                                        <Input size="sm" type="number" value={items[i].qty} onChange={(e) => setItems(prev => prev.map((item, index) => index == i ? { ...item, qty: e.target.value } : item))} />
                                    </div>

                                    <div className='flex flex-col items-end justify-end'>
                                        <button onClick={() => setItems(prev => prev.filter((_, index) => index != i))} className='w-9 h-9 flex flex-row items-center justify-center bg-red-500 text-white rounded'><RiDeleteBin4Line /></button>
                                    </div>

                                </div>
                            )) : (
                                <div className='p-6 text-center'>
                                    <h1 className='text-sm'>{t('no')}</h1>
                                </div>
                            )}

                            <button onClick={handleCreate} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>
                                {t('form.button_update')}
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div >
    );
}